//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    height: {
      type: String,
      default: () => "",
    },
  },
};
